import Carousel from "./Content/Carousel";
import LimitedOffer from "./Content/LimitedOffer";
import OurServices from "./Content/OurServices";
import PeopleSay from "./Content/PeopleSay";
import TravelAllAroundTheWorld from "./Content/TravelAllAroundTheWorld";

const Body = ({ currentPage }) => {
  const images = [
    require("../../Assets/Images/Carousel/slide1.jpg"),
    require("../../Assets/Images/Carousel/slide2.jpg"),
    require("../../Assets/Images/Carousel/slide3.jpg"),
    require("../../Assets/Images/Carousel/slide4.jpg"),
    require("../../Assets/Images/Carousel/slide5.jpg"),
  ];

  const limitedOfferImages = [
    require("../../Assets/Images/Limited offfer/GPTT Pattaya Thailand.png"),
    require("../../Assets/Images/Limited offfer/Puerto Princesa.png"),
    require("../../Assets/Images/Limited offfer/GPTT_Cebu.png"),
    require("../../Assets/Images/Limited offfer/Bohol.png"),
  ];

  const services = [
    {
      id: "0",
      img: require("../../Assets/Images/Icon/airplane_ticket.png"),
      "service-title": "Airline",
      "provided-service": "Ticketing",
      width: "103px",
      height: "82px",
    },
    {
      id: "1",
      img: require("../../Assets/Images/Icon/hotel.png"),
      "service-title": "Hotel",
      "provided-service": "Reservation",
      width: "113px",
      height: "77.5px",
    },
    {
      id: "2",
      img: require("../../Assets/Images/Icon/luggage.png"),
      "service-title": "Travel & Tour",
      "provided-service": "Packages",
      width: "72px",
      height: "103px",
    },
  ];

  const whatPeopleSay = [
    {
      id: "0",
      img: require("../../Assets/Images/What people say/image1.jpg"),
      text: `I had an incredible time exploring with this place. Highly recommend for anyone looking for a hassle-free travel experience.`,
      name: "- G*****",
    },
    {
      id: "2",
      img: require("../../Assets/Images/What people say/image2.jpg"),
      text: `Absolutely recommended! I can't wait to book my next adventure!`,
      name: "- P******",
    },
    {
      id: "3",
      img: require("../../Assets/Images/What people say/image3.jpg"),
      text: `The tour itself was a perfect balance of 
            adventure and relaxation. Will definitely 
            be recommending to friends and family
            to book a flight here and hotels.`,
      name: "- T********",
    },
  ];

  return (
    <div className="Body">
      {currentPage === "home" && (
        <div>
          <Carousel images={images} interval={5000} />
          <TravelAllAroundTheWorld />
          <LimitedOffer images={limitedOfferImages} />
          <OurServices services={services} />
          <PeopleSay testimonials={whatPeopleSay} />
        </div>
      )}
      {currentPage === "flights" && (
        <div>
          <iframe
            src="https://ph.via.com/b2bwl/gprs"
            title="via"
            style={{ width: "100%", height: "1000px" }}
          ></iframe>
        </div>
      )}
      {currentPage === "tours" && (
        <div>
          <iframe
            src="https://portal.globalpinoytravel.com/login"
            title="cygates"
            style={{ width: "100%", height: "1000px" }}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Body;
