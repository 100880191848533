import './Assets/Styles/App.css';
import Main from "./Layouts/Main/Main";


function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
