import './Footer.css';

import location from '../../Assets/Images/Icon/location_on.png';
import phone from '../../Assets/Images/Icon/call.png';
import mail from '../../Assets/Images/Icon/mail.png';

import fb from '../../Assets/Images/Icon/facebook.png';
import ig from '../../Assets/Images/Icon/instagram.png';

const Footer = () => {
    return (
        <div className='footer-container'>
            <div className='upper-footer'>
                <div className='our-company'>
                    <p className='footer-title'>Our Company</p>
                    <p className='footer-content'>
                        <b>Global Pinoy Travel and Tours –</b> GPRS Inc. major in consolidating domestic & worldwide tours for both residents and foreign travelers.
                    </p>
                    <p className='footer-content'>For instance: In-Bound, Out-Bound, Local & International Tours, Hotel Booking, Ticketing business. We organized Package Tours; provide tourist guides and/or escorts,
                        tourist vehicles, hotels & resorts reservations, domestic & international tickets.
                    </p>
                </div>
                <div className='contact-us'>
                    <p className='footer-title'>Contact Us</p>
                    <p className='footer-content contact'>
                        <img className='footer-icon location' src={location} alt={location} />
                        <p>Unit 216 Pacific Century Tower,
                            Barangay South Triangle, Quezon
                            Avenue, Quezon City.
                        </p>
                    </p>
                    <p className='footer-content contact'>
                        <img className='footer-icon phone' src={phone} alt={phone} />
                        <p>0956-716-2892 / 0998-428-9894 /
                            028-9618009
                        </p>
                    </p>
                    <p className='footer-content contact'>
                        <img className='footer-icon mail' src={mail} alt={mail} />
                        <p>inbound@globalpinoytravel.com(Supplier)
                            inboundcorp@globalpinoytravel.com(Client)</p>
                    </p>
                </div>
                <div className='mailing-list'>
                    <p className='footer-title'>
                        Mailing List
                    </p>
                    <p className='footer-content'>Please input your email if you want to
                        receive updates from us.</p>
                    <input className='footer-input' type='email' placeholder='Email Address' />
                    <button className='footer-btn'>Go</button>
                </div>
                <div className='terms-condition'>
                    <p className='footer-title'>Terms and Condition</p>
                    <p className='footer-content tc'>Accreditation and Membership</p>
                    <p className='footer-content tc'>Accepted Payments</p>
                    <p className='footer-content tc'>Travel Agents</p>
                </div>
                <div className='follow-us'>
                    <p className='footer-title'>Follow Us</p>
                    <div className='footer-icons'>
                        <img className='follow-icon' src={fb} alt={fb} />
                        <img className='follow-icon' src={ig} alt={ig} />
                    </div>
                </div>
            </div>
            <div className='lower-footer'>
                <p className='company-name'>2024 © Global Pinoy Travel and Tours.</p>
                <p className='reserved'>All rights reserved.</p>
            </div>
        </div>
    );
}

export default Footer;