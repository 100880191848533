import React, { useState } from 'react';

import './PeopleSay.css';

import arrow from '../../../Assets/Images/Icon/arrow.png';

const PeopleSay = ({ testimonials }) => {

    const [currentSlide, setCurrentSlide] = useState(0);

    const goToPrevSlide = () => {
        setCurrentSlide(currentSlide === 0 ? testimonials.length - 1 : currentSlide - 1);
    };

    const goToNextSlide = () => {
        setCurrentSlide(currentSlide === testimonials.length - 1 ? 0 : currentSlide + 1);
    };


    return (
        <div className='peopleSay-container'>
            <button className='carousel-button' onClick={goToPrevSlide}>
                <img className='left-arrow' src={arrow} alt='left-arrow' />
            </button>
            <div className='commenting-container'>
                <img className='commenting-style' src={testimonials[currentSlide].img} alt='' />
            </div>
            <div className='commentcontent-container'>
                <p className='commentcontent-title'>WHAT PEOPLE SAY</p>
                <p className='comment-content'>{testimonials[currentSlide].text}</p>
                <p className='comment-name'>{testimonials[currentSlide].name}</p>
            </div>
            <button className='carousel-button' onClick={goToNextSlide}>
                <img className='right-arrow' src={arrow} alt='right-arrow' />
            </button>
        </div>
    );
}

export default PeopleSay;