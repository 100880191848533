import React, { useState } from "react";
import Body from "../Body/Body";
import Footer from "../Footer/Footer";
import Navbar from "../Header/Navbar";

const Main = () => {
  const [currentPage, setCurrentPage] = useState("home");

  const handleLinkClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Navbar currentPage={currentPage} handleLinkClick={handleLinkClick} />
      <Body currentPage={currentPage} />
      <Footer />
    </div>
  );
};

export default Main;
