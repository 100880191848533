import React, { useState } from "react";
import "./Navbar.css";
import logo from "../../Assets/Images/Logo/image 1.png";

const Navbar = ({ currentPage, handleLinkClick }) => {
  const [showSmallBox, setShowSmallBox] = useState(false);

  const openMessenger = (userId) => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href = `fb-messenger://user-thread/${userId}`;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = `fb-messenger://user-thread/${userId}`;
    } else {
      window.open(`https://www.facebook.com/messages/t/${userId}`, "_blank");
    }
  };

  const toggleSmallBox = () => {
    setShowSmallBox(!showSmallBox); // Toggle the state to show/hide the small box
  };

  return (
    <div>
      <div className="header__container">
        <div className="header__logo">
          <img src={logo} className="header__logo" alt="GPTT LOGO" />
        </div>
        <div className="header__links">
          <ul>
            <li>
              <p onClick={() => handleLinkClick("home")}>Home</p>
            </li>
            <li>
              <p>Services</p>
            </li>
            <li>
              <p>Hotels</p>
            </li>
            <li>
              <p>About Us</p>
            </li>
            <li>
              <p>Contact Us</p>
            </li>
            <li onClick={toggleSmallBox}>
              <p>Travel Inquiry</p>
              <div
                className={
                  showSmallBox ? "small-box show-small-box" : "small-box"
                }
              >
                <p className="inquire__text">Chat Us:</p>
                <div className="icon__container">
                  <p
                    className="messenger__link"
                    onClick={() => openMessenger("100072440635186")}
                  >
                    Flights / Ferries / Travel Insurance
                  </p>
                  <p
                    className="messenger__link"
                    onClick={() => openMessenger("410324432348367")}
                  >
                    Travel and Tours / Airport Transfers / Hotels / Visa
                    Assistance / Passporting / Wi-Fi / NSO / NBI
                  </p>
                </div>

                <div className="inquire__call">
                  <p className="centered">OR</p>
                  <p className="inquire__text">Call Us:</p>
                  <p className="call-text">
                    0946-718-5309 - Flights / Ferries / Travel Insurance
                  </p>
                  <p className="call-text">
                    0956-716-2892 - Travel and Tours / Airport Transfers /
                    Hotels / Visa Assistance / Passporting / Wi-Fi / NSO / NBI
                  </p>
                </div>
              </div>
            </li>
            <li onClick={() => handleLinkClick("flights")}>
              <p>Flights</p>
            </li>
            <li onClick={() => handleLinkClick("tours")}>
              <p>Tours and Visa</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
